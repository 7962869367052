import { useState } from 'react'
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import logo from "../../assets/logo_circle_blue.png"

import register1 from '../../assets/captures/register_1.png'
import register2 from '../../assets/captures/register_2.png'
import register3 from '../../assets/captures/register_3.png'

import test1 from '../../assets/captures/test_1.png'
import test2 from '../../assets/captures/test_2.png'

import survey1 from '../../assets/captures/survey_1.png'
import survey2 from '../../assets/captures/survey_2.png'
import survey3 from '../../assets/captures/survey_3.png'
import survey4 from '../../assets/captures/survey_4.png'

import promo1 from '../../assets/captures/promo_1.png'
import promo2 from '../../assets/captures/promo_2.png'

const tabs = [
  { title: "Instagram", id: "instagram" },
  { title: "Web", id: "web" },
  { title: "Registro", id: "register" },
  { title: "Prueba", id: "test" },
  { title: "Encuestas", id: "survey" },
  { title: "Promociones", id: "promo" },
]

const Code = () => {
  const [current, setCurrent] = useState("instagram")

  const params = useParams();
  //params.code
  return (
    <div className="text-center">
      <img src={logo} style={{ height: 100, width: 100 }} />
      <h1 className="mt-3">código: <b className="text-trulivers">{params.code}</b></h1>
      <ul className="nav nav-pills nav-fill mb-3">
        {tabs.map((tab) => {
          return <li className="nav-item" key={tab.id} onClick={() => setCurrent(tab.id)}>
            <a className={`nav-link ${tab.id === current ? 'active' : ''}`}>{tab.title}</a>
          </li>
        })}
      </ul>
      {current === 'web' ? <Web code={params.code} /> : ''}
      {current === 'instagram' ? <Instagram /> : ''}
      {current === 'register' ? <Register /> : ''}
      {current === 'test' ? <Test /> : ''}
      {current === 'survey' ? <Survey /> : ''}
      {current === 'promo' ? <Promo /> : ''}
    </div>
  )
}
const Web = (code) => {
  return (
    <>
      <h2 className="mt-3 text-trulivers">https://tav.trulivers.com</h2>
      <QRCode className="mt-3" value={`https://tav.trulivers.com/${code}`} />
    </>
  )
}
const Instagram = () => {
  return (
    <>
      <h2 className="mt-3 text-trulivers">@trulivers.app</h2>
      <QRCode className="mt-3" value="https://www.instagram.com/trulivers.app" />
    </>
  )
}

const Register = () => {
  return (
    <>
      <h2 className="mt-3 text-trulivers">Ingresa con Google o Apple, para registrarte.</h2>
      <img src={register1} />
      <h2 className="mt-3 text-trulivers">Completa los datos restantes.</h2>
      <img src={register2} />
      <h2 className="mt-3 text-trulivers">Selecciona las categorías Educación y General.</h2>
      <img src={register3} />
    </>
  )
}

const Test = () => {
  return (
    <>
      <h2 className="mt-3 text-trulivers">Pulsa al botón de ¡Hola!</h2>
      <img src={test1} />
      <h2 className="mt-3 text-trulivers">Ingresa el código de tu institución.</h2>
      <img src={test2} />
    </>
  )
}

const Survey = () => {
  return (
    <>
      <h2 className="mt-3 text-trulivers">Luego de recibir el resultado de áreas.</h2>
      <img src={survey1} />
      <h2 className="mt-3 text-trulivers">Realiza la encuesta de estudiantes.</h2>
      <img src={survey2} />
      <h2 className="mt-3 text-trulivers">También la encuesta de la Secretaría de Educación.</h2>
      <img src={survey3} />
      <h2 className="mt-3 text-trulivers">Y por último, podrás ver las carreras relacionadas con las áreas.</h2>
      <img src={survey4} />
    </>
  )
}

const Promo = () => {
  return (
    <>
      <h2 className="mt-3 text-trulivers">Revisa las carreras y promociones vigentes.</h2>
      <img src={promo1} />
      <h2 className="mt-3 text-trulivers">Compra y usa los cupones con descuentos.</h2>
      <img src={promo2} />
    </>
  )
}
export default Code