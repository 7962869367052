import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SedSurvey = () => {
    const [clicked, setClicked] = useState(false)
    const navigate = useNavigate()
    return <div className='text-center'>
        <h1 className="text-trulivers">Encuesta Secretaría de Educación</h1>
        <button className={`btn mt-5 mb-5 ${clicked ? 'btn-success' : 'btn-primary background-trulivers'}`} onClick={() => {
            window.open("https://docs.google.com/forms/d/e/1FAIpQLScU3i-u_Gs6h2Zm7R6dorfCaz-BXg9BTovUtc5TJ8Z3OI4HmQ/viewform", '_blank', 'noopener,noreferrer')
            setClicked(true)
        }}>Realizar encuesta</button>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button className="btn btn-primary background-trulivers" type="button" onClick={() => {
                if (clicked) navigate("/end")
                else alert("Debes realizar la encuesta de la Secretaría de Educación")
            }}>Continuar</button>
        </div>
    </div>;
};

export default SedSurvey;