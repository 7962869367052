import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

//TODO agregar respuestas del test
const fields = [
    { title: 'Nombre completo', id: 'name' },
    { title: 'Datos personales', id: 'personalData' },
    { title: 'Datos acudiente', id: 'parentData' },
    { title: 'Datos escuela', id: 'schoolData' },
    { title: 'Carrera de preferencia', id: 'preferedCareer' },
    { title: 'Areas sugeridas', id: 'suggestedAreas' },
]

const Reports = () => {

    const [config, setConfig] = useState({ query: 'all', code: '' })
    const [codes, setCodes] = useState([])

    useEffect(() => {
        getCodes()
    }, [])

    const cues = async () => {
        const notDev = new Parse.Query("Result").notEqualTo("code", "1r9Ga")
        const notDemo = new Parse.Query("Result").notEqualTo("code", "59jV5")
        const results = await Parse.Query
            .and(notDev, notDemo)
            .limit(9999)
            .exists("user")
            .find()
        let data = [];
        results.forEach((result) => {
            let info = {};
            info = getPersonalData(result, info)
            info = getParentData(result, info)
            info = getSchoolData(result, info)
            info = getPreferedCarreer(result, info)
            info = getSugdestedAreas(result, info)
            data.push(info)
        })
        getExcel(data, "ResultadoEstudiantesColegios")
    }
    const exportData = async () => {
        if (config.query === 'single' && config.code === '') {
            alert("Debe seleccionar un código")
            return
        }
        if (!config.personalData && !config.parentData && !config.schoolData && !config.preferedCareer && !config.suggestedAreas) {
            alert("Debe seleccionar almenos una seccion de campos")
            return
        }

        let query;

        if (config.query === 'all') {
            const notDev = new Parse.Query("Result").notEqualTo("code", "1r9Ga")
            const notDemo = new Parse.Query("Result").notEqualTo("code", "59jV5")
            query = Parse.Query
                .and(notDev, notDemo)
                .limit(9999)
                .exists("user")
        } else {
            query = new Parse.Query("Result").equalTo('code', config.code).exists("user")
        }

        const results = await query.find();

        let data = [];
        results.forEach((result) => {
            let info = {};
            if (config.name) info = getName(result, info)
            if (config.personalData) info = getPersonalData(result, info)
            if (config.parentData) info = getParentData(result, info)
            if (config.schoolData) info = getSchoolData(result, info)
            if (config.preferedCareer) info = getPreferedCarreer(result, info)
            if (config.suggestedAreas) info = getSugdestedAreas(result, info)
            
            data.push(info)
        })
        let fileName = "";
        if(config.query !== 'all') codes.forEach((code) => {
            if(code.get('code') === config.code) fileName = code.get("entity")
        })
        getExcel(data, `InformeTrulivers - ${fileName} - ${new Date().toLocaleString()}`)
    }
    const getCodes = async () => {
        const codes = await new Parse.Query("Code").find();
        setCodes(codes)
    }
    const getPersonalData = (result, data) => {
        let user = result.get("user");
        data.name = user.name;
        data.phone = user.phone;
        data.email = user.email;
        data.age = user.age;
        data.genre = user.genre;
        return data
    }
    const getParentData = (result, data) => {
        let user = result.get("user");
        data.parentName = user.parentName;
        data.parentEmail = user.parentEmail;
        data.parentPhone = user.parentPhone;
        return data;
    }
    const getSchoolData = (result, data) => {
        let user = result.get("user");
        data.school = user.school;
        data.grade = user.grade;
        return data;
    }
    const getPreferedCarreer = (result, data) => {
        let survey = result.get("survey")
        if (!survey) return data;
        data.carreerPrefered = survey["7_que_carrera_has_identificado_para_estudiar_y_como_la_elegiste"] || survey["que_carreras_has_contemplado_para_estudiar"] || survey["question5"]
        return data
    }
    const getSugdestedAreas = (result, data) => {
        result.get("result").forEach((area, index) => {
            if (index < 3) data[`suggestion${index+1}`] = area.title
        })
        /*data.areas = ""
        result.get("result").forEach((area, index) => {
            if (index < 3) data.areas += area.title + (index < 2 ? ", " : "")
        })*/
        return data
    }
    const getName = (result, data) => {
        let user = result.get("user");
        data.name = user.name;
        return data
    }
    const getExcel = (data, name) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${name}.xlsx`);
    };
    return <div>
        <div className="form-check">
            <input className="form-check-input" type="radio" name="query" id="all" checked={config.query === 'all'} onChange={() => setConfig({ ...config, query: 'all' })} />
            <label className="form-check-label" htmlFor="all">
                Todos
            </label>
        </div>
        <div className="form-check">
            <input className="form-check-input" type="radio" name="query" id="single" checked={config.query === 'single'} onChange={() => setConfig({ ...config, query: 'single' })} />
            <label className="form-check-label" htmlFor="single">
                Por código
            </label>
        </div>

        <select className="form-select mb-3" aria-label="code" name="code" disabled={config.query === 'all'} value={config.code} onChange={(evt) => setConfig({ ...config, code: evt.target.value })}>
            <option value=""></option>
            {codes.map((code) => {
                return <option value={code.get('code')} key={code.id}>{code.get('entity')}</option>
            })}
        </select>
        <hr />
        <div className='mb-3'>
            {fields.map((field, index) => {
                return <div className="form-check" key={index}>
                    <input className="form-check-input" type="checkbox" checked={config[field.id]} onChange={(evt) => {
                        let copy = { ...config }
                        copy[field.id] = evt.target.checked
                        setConfig(copy)
                    }} />
                    <label className="form-check-label" htmlFor="personalData">
                        {field.title}
                    </label>
                </div>
            })}
        </div>
        <button className='btn btn-primary' onClick={exportData}>Exportar</button>

    </div>;
};

export default Reports;